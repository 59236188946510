<template>
    <div class="mobileverify-mobile">
        <!-- step 1 -->
        <div class="verifyForm" v-if="step==1">
            <div class="formheader" >
                {{step1Title}}
            </div>

            <el-form ref="verifyForm" :model="form" :rules="verifyRules" label-position="top">
                <el-form-item class="inputitem" :label="countryLabel" prop="country" label-position="top">
                    <el-select class="countrySelect" style="width: 100%" filterable :filter-method="countryFilter" v-model="form.country" @change="onCountryChange"
                               :placeholder="countryPlaceholder">
                        <el-option v-for="item in countryDatas"
                                   :key="item.countryCode"
                                   :label="item.countryName"
                                   :value="item.countryCode">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="inputitem-two" :label="phoneAreaLabel" prop="phoneArea" label-position="top">
                    <el-select class="phoneAreaSelect" filterable :filter-method="areaFilter" v-model="form.country" @change="onCountryChange" :placeholder="phoneAreaPlaceholder">
                        <el-option
                                v-for="item in phoneAreas"
                                :key="item.countryCode"
                                :label="item.areaCode"
                                :value="item.countryCode">
                        </el-option>
                    </el-select>
                    <el-input class="phone-input"  v-model="form.phone" :placeholder="phonePlaceholder"></el-input>
                </el-form-item>
                <el-button type="primary" class="step1-button"
                           @click.native="doSendCode" :disabled="sendCodeDisabled">{{step1ButtonText}}</el-button>
                <div class="step1-bottom">
                    <div class="bottomnotice">{{noticeText}}</div>
                </div>
            </el-form>

        </div>
        <!-- step 2 -->
        <div class="verifyForm" v-if="step==2">
            <div class="formheader" >
                {{step2Title}}
            </div>
            <div class="verifyline">
                <div class="content">
                    <div class="phoneline">
                        <div class="phoneitem">{{phoneLabel}}</div>
                        <div class="phoneitem">{{form.phoneArea}}-{{form.phone}}</div>
                    </div>

                    <div class="nodity">{{phoneNotifyContent}}</div>
                    <div class="clickedinfo">{{clickinfo}}</div>
                </div>
            </div>
            <el-form ref="authForm" :model="form" label-width="140px" :rules="authRules" label-position="top">

                <el-form-item class="authencodebox" :label="verificationCode" prop="authcode" label-position="top">
                    <div class="authenCode-view" style="background: #FFFFFF;
                border: 1px solid #CAD3DE;
                border-radius: 4px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;">
                        <el-input v-model="authcode" :placeholder="authcodePlaceholder" style="border: none; border-radius: 0px"></el-input>
                        <div class="resend" @click="doResend()">{{resendText}}</div>
                    </div>

                </el-form-item>

            </el-form>
            <el-button type="primary" class="step2-button" :disabled="submitDisabled" @click.native="doSubmit">{{step2ButtonText}}</el-button>

        </div>
        <!-- step 3 -->
        <div class="verifyForm" v-if="step==3">
            <div class="formheader" >
            </div>
            <div class="succesbox">
                <div class="success" ></div>
            </div>
            <div class="successinfobox">
                <div class="successinfo">{{successinfo}}</div>
            </div>

            <div class="bottom">
                <el-button type="primary" class="bottom-login-button" @click.native="loginClick">{{signinText}}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import index from './index'
    export default index
</script>

<style lang="less">
    .mobileverify-mobile {
        padding: 20px;
        .verifyForm{
            width: 100%;
            background: #FFFFFF;
        }

        .formheader{
            margin-top: 8px;
            color:#333333;
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 30px;
            font-weight: bold;
        }
        .emptyline{
            height: 30px;
        }

        .inputitem{

        }
        .inputitem-two{
            .phoneAreaSelect {
                width: 120px;
                height: 50px;
            }
            .phone-input {
                margin-left: 15px;
                height: 50px;
                width: calc(100% - 140px);
            }
        }
        .step1-button{
            margin-top: 20px;
            width: 100%;
            height: 50px;
            background-color: #0073FF;
            box-shadow: 0 0 10px 0 rgba(0,115,255,0.20);
            font-size: 18px;
        }

        .step1-bottom{
            margin-top: 30px;
            width: 100%;
            display: flex;
        }

        .bottomnotice{
            color: #ACBDC7;
            font-size: 14px;
            letter-spacing: 0;
        }

        .verifyline{
            /*height: 100px;*/
            display: flex;
            margin-bottom: 30px;
            .content{
                /*margin-left: 20px;*/
                color:#333333
            }
            .phoneline{
                display: flex;
            }
            .phoneitem{
                font-size: 16px;
                line-height: 22px;
            }
            .nodity {
                margin-top: 30px;
                font-size: 14px;
                line-height: 20px;
                color: #666666;
            }
            .clickedinfo{
                color:#BDC3D2;
                font-size: 14px;
            }

        }

        .icon{
            width: 100px;
            height: 100px;
            background:url(../../../assets/images/phone.png) no-repeat 0 0;
            background-size: 100% 100%;
        }

        .notice{
            width: 520px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: #81899E;
        }

        .verifycodebox{
            width: 100%;
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            .authencodebox{
                height: 40px;
                width: 100%;
                margin-right: 40px;
                background: #FFFFFF;
            }
            .authenCode-view {

            }

        }
        .resend{
            width: 80px;
            text-align: right;
            margin-right: 10px;
            color: #0073FF;
        }
        .step2-button{
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
            background-color: #0073FF;
            box-shadow: 0 0 10px 0 rgba(0,115,255,0.20);
        }
        .succesbox{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .success{
            width: 100px;
            height: 100px;
            background:url(../../../assets/images/success.png) no-repeat 0 0;
            background-size: 100% 100%;
            /*margin-top: -50px;*/
        }

        .successinfobox{
            margin-top: 20px;
            display: flex;
            justify-content: center;


        }
        .successinfo{
            font-size: 20px;
            color: #274776;
        }

        .bottom{
            /*height: 50px;*/
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .bottom-login-button {
            margin-top: 40px;
            width: 100%;
            height: 50px;
            background-color: #0073FF;
            box-shadow: 0 0 10px 0 rgba(0,115,255,0.20);
            font-size: 18px;
            color: #ffffff;
        }

        .verifyForm .el-input__inner {
            height: 50px;
            line-height: 50px;
        }
        .authencodebox .el-input__inner {
            border: 0 none;
            border-radius: 0px;
            height: 50px;
            /*width: 300px;*/
            line-height: 50px;
        }
        .verifyForm .el-form-item__label {
            line-height: 20px;
        }
    }
</style>
